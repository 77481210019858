import type { Service } from '../domain/service.interface';
import type { Industry } from '$lib/domain/industry.interface';

export const relatedUrlList: string [] = [
  '/how-to-build-a-product-from-scratch/',
  '/healthcare-of-the-future/',
  '/basics-of-developing-on-demand-service-app/',
  '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
  '/our-works/construction-supply-chain-automation/'
];
export const services: Service[] = [
  {
    title: 'Startup Studio',
    content: 'Bring your idea to life on time and within budget with Exoft as your tech business partner. We can take you from a raw concept to a fully functional product, eliminating hesitation at every software development stage. With our expertise, we’ll crystallize your vision into tech requirements, create user-friendly UI/UX design, develop a solid codebase, and prepare the product for launch.',
    iconName: 'startup',
    url:'/software-development-for-startups/'
  },
  {
    title: 'Product Design & Development',
    content: 'Introduce a product that’s tailored to your customers\' needs and relevant to your target market. Create a technical solution that increases the efficiency of your teams and supports the company\'s internal goals. As an experienced software development service provider, we can take care of every product development step, from ideation and UI/UX design to coding, quality assurance, and post-release support.',
    iconName: 'productDesign',
    url:'/product-development-services/'
  },
  {
    title: 'Platform Development',
    content: 'Create a digital platform loaded with tools and services that engage customers and build a strong brand presence. As a seasoned platform development company, we can build a scalable tech architecture, set up cloud environments, integrate APIs, and optimize storage resources. Our teams can create custom solutions or optimize existing ones, especially for web, mobile, IoT, and enterprise platforms.',
    iconName: 'IoTPlatformDevelopment',
    url:'/platform-development/'
  },
  {
    title: 'Technology Modernization',
    content: 'Move away from legacy tech stacks that hinder product performance, limit scalability, and drive up maintenance costs. As a technology modernization services provider, we can update your backend and frontend while keeping your features intact and operations uninterrupted. Partner with Exoft for smooth cloud migration, streamlined database management, and efficient CI/CD pipelines.',
    iconName: 'highPerformance',
    url:'/technology-modernization/'
  },
  {
    title: 'Application Modernization',
    content: 'Ensure your application is up-to-date and aligned with your business requirements and customer expectations. We can upgrade every component of your solution, creating a new UI/UX or rebuilding your codebase altogether. By opting for our application modernization services, you can seamlessly adopt modular architectures, re-platform for the cloud, or migrate to an entirely new application.',
    iconName: 'rapidDevelopment',
    url:'/application-modernization-services/'
  },
  {
    title: 'Team Extension',
    content: 'Augment your existing team with additional talent while delegating recruitment, hiring, financial management, and employee retention to Exoft. As the top software development company, we can provide you with an entire dedicated team or individual experts: BAs, project managers, designers, software engineers, and testers. We make sure that our specialists will have the required technical skills, fit your corporate culture, and seamlessly integrate into your internal processes.',
    iconName: 'teamStars',
    url:'/team-extension-services/'
  },
  {
    title: 'IT Support',
    content: 'Maintain and evolve your product even after the release date, giving your user base the level of customer service they deserve. Our software development service company offers Levels 2 and 3 of technical support and software maintenance services. We can resolve recurrent problems and handle performance monitoring, regular updates, upgrades, and beyond.',
    iconName: 'techSupport',
    url:'/support-and-maintenance/'
  }
];
export const expertises: Service[] = [
  {
    iconName: 'webDevelopment',
    title: 'Web application development',
    content: 'We are well-versed in modern web development technologies. Our web development team can build an attractive website or web app with an intuitive frontend, a stable backend, and a perfectly structured database.',
    url:'/web-development/',
  },
  {
    iconName: 'mobileDevelopment',
    title: 'Mobile application development',
    content: 'Our team has a proven track record of successfully completed mobile app development projects. We know how to build perfect Android, iOS, or cross-platform mobile apps with intuitive design and just the right set of features to win users’ hearts and minds.',
    url:'/mobile-development/',
  },
  {
    iconName: 'uiUxDesign',
    title: 'UI/UX design',
    content: 'Our expertise covers all aspects of visual and user experience design, from user research and prototyping to usability testing. We create appealing, fast-responsive, and easy-to-navigate interfaces that mirror the brand’s identity and promote customer attribution and retention.',
    url:'/ui-ux-design-services/',
  },
  {
    iconName: 'qaTesting',
    title: 'QA services',
    content: 'We are adept in all aspects of quality assurance. We can create test scripts to ensure maximum coverage, set up scalable and versatile testing environments, run all types of tests, and more. Nothing flies under the radar of our QA team.',
    url:'/quality-assurance/',
  },
  {
    iconName: 'crmBusiness',
    title: 'Business application development',
    content: 'with a vast project portfolio spanning domains, we are well aware of business-related challenges — which makes us masters of enterprise software development. We can craft powerful ERP, CRM, and other corporate solutions perfectly aligned with your business goals.',
    url:'/enterprise-application-development-services/',
  },
];
export const industries: Industry[] = [
  {
    title: 'Healthcare',
    content: 'Whether you are a startup ambitious to make this world a safer place with a novel health tech solution or a medical professional looking to streamline processes in your clinic, our team can help you out.<br><br>As a trusted healthcare software development service provider, we can help you create a telemedicine platform, an EHR system, or a LIMS that complies with the standards of modern health tech.',
    url: '/healthcare-software-development/',
    icon: 'healthcare.webp',
  },
  {
    title: 'Logistics',
    content: 'Our software development company can empower freight forwarders, manufacturers, distributors, warehouse owners, and other supply chain participants with high-end custom solutions.<br><br>Logistics software developed by our team can optimize routes based on weather forecasts and traffic, track shipments, anticipate delays, digitize paperwork, and otherwise streamline supply chain processes — thus eliminating inefficiencies and cutting expenses.',
    url: '/logistics-software-development/',
    icon: 'logistics.webp',

  },
  {
    title: 'GreenTech',
    content: 'Partner with Exoft to deliver software solutions that convert your sustainability efforts into meaningful environmental change and long-term business value. We can take your GreenTech project from the first idea to its successful growth.<br><br>As a trusted GreenTech development company with expertise in cloud computing, AI, and IoT, we build solutions for managing waste, reducing reliance on nonrenewables, and achieving net-zero transition.',
    url: '/greentech-software-development/',
    icon: 'greenTech.webp'
  },
  {
    title: 'Finance',
    content: 'We are a trusted software development company with solid knowledge of the finance domain. We can build scalable solutions that securely deal with large amounts of sensitive data in line with all regulations.<br><br>We can help you with solutions for mobile banking, personal budgeting, invoice management, and beyond. Fintech products built by our team help users transfer funds securely, set budgeting goals, get financial advice, and much more.',
    url: '/finance-software-development/',
    icon: 'finance.webp'
  },
  {
    title: 'Education',
    content: 'At Exoft, we excel at building software that enables remote learning, adapts the learning processes to individual student needs, promotes collaboration between teachers and students, automates paperwork, or otherwise improves teaching or learning.<br><br>We can build virtual classrooms, shared document editing solutions, student forums, educational content creation tools, assessment solutions, etc.',
    url: '/education-software-development-services/',
    icon: 'education.webp'
  },
  {
    title: 'Construction',
    content: 'Tech solutions built for construction businesses are part of our company’s portfolio. We can lend you a hand with software for construction management, BIM, accounting, document management, equipment maintenance, and beyond.<br><br>Our custom development services help companies plan construction work, track construction equipment, facilitate smooth cooperation between contractors and other stakeholders, and enhance logistics on construction sites — thus reducing delays, risks, and expenses.',
    url: '/construction-software-development/',
    icon: 'construction.webp'
  },
  {
    title: 'Energy and Utilities',
    content: 'Our custom software development services benefit water and electricity distributors, facility operators, energy producers, oil refineries, and mining companies.<br><br>As a software development company with a decade of experience, we can build solutions that track production waste, notify about equipment failures, monitor asset wear and tear, and measure consumption intensity. Whether you need an electric vehicle charging app, a meter reading software, or a fuel tracking solution, our engineers can make it happen.',
    url: '/energy-and-utilities-software-development-services/',
    icon: 'energy-and-utilities.webp'
  },
  {
    title: 'Media & Entertainment',
    content: 'Media and entertainment businesses partner with us to build user-centric software solutions for smooth content distribution, accurate user segmentation, and personalized ad campaigns.<br><br>As a mature software development company, we design, architect, and develop OTT platforms, media portals, AdTech applications, and engagement analytics engines for any market size. Our M&E solutions allow you to easily create, publish, and stream digital media that win and keep audiences.',
    url: '/media-and-entertainment-software-development/',
    icon: 'media-and-entertainment.webp'
  },
];